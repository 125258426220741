import { chakra, HTMLChakraProps } from "@chakra-ui/react"
import * as React from "react"

export const Bg = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 800 800"
  >
    <defs>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="ffflurry-grad"
        gradientTransform="rotate(270)"
      >
        <stop
          stop-color="rgba(255, 255, 255, 0)"
          stop-opacity="1"
          offset="0%"
        ></stop>
        <stop
          stop-color="hsl(220, 100%, 60%)"
          stop-opacity="1"
          offset="45%"
        ></stop>
        <stop
          stop-color="hsl(167, 41%, 74%)"
          stop-opacity="1"
          offset="100%"
        ></stop>
      </linearGradient>
    </defs>
    <rect width="100%" height="100%" fill="rgba(255, 255, 255, 0)"></rect>
    <g fill="url(#ffflurry-grad)">
      <rect
        width="103"
        height="1"
        x="542.5"
        y="537.5"
        rx="0.5"
        transform="rotate(193, 594, 538)"
        opacity="0.07"
      ></rect>
      <rect
        width="659"
        height="1"
        x="59.5"
        y="555.5"
        rx="0.5"
        transform="rotate(193, 389, 556)"
        opacity="0.12"
      ></rect>
      <rect
        width="508"
        height="1"
        x="390"
        y="91.5"
        rx="0.5"
        transform="rotate(193, 644, 92)"
        opacity="0.39"
      ></rect>
      <rect
        width="317"
        height="1"
        x="6.5"
        y="194.5"
        rx="0.5"
        transform="rotate(193, 165, 195)"
        opacity="0.62"
      ></rect>
      <rect
        width="421"
        height="1"
        x="523.5"
        y="530.5"
        rx="0.5"
        transform="rotate(193, 734, 531)"
        opacity="0.96"
      ></rect>
      <rect
        width="161"
        height="1"
        x="49.5"
        y="671.5"
        rx="0.5"
        transform="rotate(193, 130, 672)"
        opacity="0.29"
      ></rect>
      <rect
        width="148"
        height="1"
        x="587"
        y="703.5"
        rx="0.5"
        transform="rotate(193, 661, 704)"
        opacity="0.87"
      ></rect>
      <rect
        width="420"
        height="1"
        x="269"
        y="301.5"
        rx="0.5"
        transform="rotate(193, 479, 302)"
        opacity="0.91"
      ></rect>
      <rect
        width="419"
        height="1"
        x="-152.5"
        y="128.5"
        rx="0.5"
        transform="rotate(193, 57, 129)"
        opacity="0.82"
      ></rect>
      <rect
        width="313"
        height="1"
        x="-75.5"
        y="426.5"
        rx="0.5"
        transform="rotate(193, 81, 427)"
        opacity="0.67"
      ></rect>
      <rect
        width="668"
        height="1"
        x="9"
        y="716.5"
        rx="0.5"
        transform="rotate(193, 343, 717)"
        opacity="0.27"
      ></rect>
      <rect
        width="367"
        height="1"
        x="320.5"
        y="430.5"
        rx="0.5"
        transform="rotate(193, 504, 431)"
        opacity="0.33"
      ></rect>
      <rect
        width="295"
        height="1"
        x="384.5"
        y="758.5"
        rx="0.5"
        transform="rotate(193, 532, 759)"
        opacity="0.23"
      ></rect>
      <rect
        width="83"
        height="1"
        x="714.5"
        y="719.5"
        rx="0.5"
        transform="rotate(193, 756, 720)"
        opacity="0.81"
      ></rect>
      <rect
        width="279"
        height="1"
        x="234.5"
        y="66.5"
        rx="0.5"
        transform="rotate(193, 374, 67)"
        opacity="0.85"
      ></rect>
      <rect
        width="92"
        height="1"
        x="391"
        y="176.5"
        rx="0.5"
        transform="rotate(193, 437, 177)"
        opacity="0.76"
      ></rect>
      <rect
        width="484"
        height="1"
        x="284"
        y="656.5"
        rx="0.5"
        transform="rotate(193, 526, 657)"
        opacity="0.61"
      ></rect>
      <rect
        width="680"
        height="1"
        x="-65"
        y="425.5"
        rx="0.5"
        transform="rotate(193, 275, 426)"
        opacity="0.20"
      ></rect>
      <rect
        width="176"
        height="1"
        x="655"
        y="229.5"
        rx="0.5"
        transform="rotate(193, 743, 230)"
        opacity="0.21"
      ></rect>
      <rect
        width="61"
        height="1"
        x="173.5"
        y="54.5"
        rx="0.5"
        transform="rotate(193, 204, 55)"
        opacity="0.72"
      ></rect>
      <rect
        width="342"
        height="1"
        x="80"
        y="265.5"
        rx="0.5"
        transform="rotate(193, 251, 266)"
        opacity="0.62"
      ></rect>
      <rect
        width="483"
        height="1"
        x="431.5"
        y="354.5"
        rx="0.5"
        transform="rotate(193, 673, 355)"
        opacity="0.38"
      ></rect>
    </g>
  </chakra.svg>
)
